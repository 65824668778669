import {Button, Checkbox, Col, Divider, Modal, Row, Typography} from 'antd';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {getTranslationAssetTableColumnKey} from '../../../utils/translation';
import {capitalize} from 'lodash';
import {DEFAULT_COLUMNS} from '../../../utils/assets';
import {useDispatch, useSelector} from 'react-redux';
import {
  insertColumnsAtTheEnd,
  removeColumns,
  selectAssetsTableColumns,
  setColumns,
} from '../../../redux/slices/assets';
import {
  AssetsTableColumn,
  BuildingDetailsColumn,
  PositionColumn,
  RealEstateColumn,
} from '../../../types/internal/tables/assetsTable';
import {useGetHazardTypeClassesQuery} from '../../../redux/api/hazardTypes';

function getColumnsBySection(title: 'position' | 'buildingDetails' | 'realEstateData') {
  switch (title) {
    case 'buildingDetails':
      return ['sigi_code', 'master_asset', 'building_type', 'building_type_detail', 'companies', 'total_resources',
        'surface_basement', 'surface_total', 'opening_date', 'closing_date', 'ownership',
        'is_user_created', 'biodiversity_within', 'total_uog_contacts'] as BuildingDetailsColumn[];
    case 'position':
      return ['country', 'admin_level_1', 'admin_level_2', 'admin_level_3', 'address', 'postal_code'] as PositionColumn[];
    case 'realEstateData':
      return ['realestate_hub', 'contact_people'] as RealEstateColumn[];
  }
}

function ColumnsSection({title}: { title: 'position' | 'buildingDetails' | 'realEstateData', }) {

  const {t} = useTranslation();
  const columns = useSelector(selectAssetsTableColumns);
  const dispatch = useDispatch();
  const handleChange = (key: AssetsTableColumn, isChecked: boolean) => {
    if (isChecked) {
      dispatch(insertColumnsAtTheEnd([key]));
    } else {
      dispatch(removeColumns([key]));
    }
  };

  return <div style={{display: 'flex', flexDirection: 'column', gap: '0.75rem'}}>
    <Typography.Title level={4} style={{margin: 0}}>{capitalize(t(`misc.${title}`))}</Typography.Title>
    <Row style={{marginLeft: '1rem'}} gutter={[12, 12]}>
      {getColumnsBySection(title).map(el => (
        <Col
          key={el}
          span={12}
        >
          <Checkbox
            checked={columns.includes(el)}
            onChange={e => handleChange(el, e.target.checked)}
          >
            {capitalize(t(getTranslationAssetTableColumnKey(el)))}
          </Checkbox>
        </Col>
      ))}
    </Row>
  </div>;
}

function HazardSection() {
  const {
    data: hazardTypes,
  } = useGetHazardTypeClassesQuery();
  const {t} = useTranslation();
  const columns = useSelector(selectAssetsTableColumns);
  const hazardsColumns = hazardTypes?.map(el => el.code) || [];
  const isToRemoveHazardsColumns = columns.some(el => hazardsColumns.includes(el));

  const dispatch = useDispatch();
  const handleChange = (key: AssetsTableColumn, isChecked: boolean) => {
    if (isChecked) {
      dispatch(insertColumnsAtTheEnd([key]));
    } else {
      dispatch(removeColumns([key]));
    }
  };

  return <div style={{display: 'flex', flexDirection: 'column', gap: '0.75rem'}}>
    <Row justify={'space-between'}>
      <Col>
        <Typography.Title level={4} style={{margin: 0}}>
          {capitalize(t('hazards.hazard', {count: 2}))}
        </Typography.Title>
      </Col>
      <Col>
        <Button
          onClick={() => {
            if (isToRemoveHazardsColumns) {
              dispatch(removeColumns(hazardsColumns));
            } else {
              dispatch(insertColumnsAtTheEnd(hazardsColumns));
            }
          }}
        >
          {isToRemoveHazardsColumns && capitalize(t('actions.deselectAll'))}
          {!isToRemoveHazardsColumns && capitalize(t('actions.selectAll'))}
        </Button>
      </Col>
    </Row>
    <Row style={{marginLeft: '1rem'}} gutter={[12, 12]}>
      {!!hazardTypes && hazardTypes?.map(ht => (
        <Col
          key={ht.code}
          span={12}
        >
          <Checkbox
            checked={columns.includes(ht.code)}
            onChange={e => handleChange(ht.code, e.target.checked)}
          >
            {capitalize(ht.name)}
          </Checkbox>
        </Col>
      ))}
    </Row>
  </div>;
}

export default function AssetsTableColumnsSelection() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  return <>
    <Button
      onClick={() => setIsModalVisible(true)}
    >
      {capitalize(t('table.selectColumns'))}
    </Button>
    <Modal
      title={capitalize(t('table.selectColumns'))}
      open={isModalVisible}
      footer={<Row justify={'end'} style={{marginTop: '2rem'}}>
        <Col>
          <Button
            danger
            onClick={() => dispatch(setColumns(DEFAULT_COLUMNS))}
          >
            {capitalize(t('form.reset'))}
          </Button>
        </Col>
      </Row>}
      onCancel={() => setIsModalVisible(false)}
      closeIcon={false}

    >
      <div style={{display: 'flex', flexDirection: 'column', gap: '1.5rem'}}>
        <Divider style={{margin: 0}}/>
        <ColumnsSection title={'position'}/>
        <Divider style={{margin: 0}}/>
        <ColumnsSection title={'buildingDetails'}/>
        <Divider style={{margin: 0}}/>
        <ColumnsSection title={'realEstateData'}/>
        <Divider style={{margin: 0}}/>
        <HazardSection/>
      </div>
    </Modal>
  </>;
}