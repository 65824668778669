import {Col, Pagination, Row, Space, Table, Typography} from 'antd';
import _, {capitalize} from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import DeleteMultipleDocuments from '../DeleteMultipleDocuments';
import {DownloadMultipleDocuments} from '../DownloadMultipleDocuments';
import {
  DocumentType,
  EventTypesDocumentsType,
  useDeleteDocumentMutation,
  useLazyGetDocumentsByEventTypeQuery,
} from '../../../redux/api/documents';
import {dateToLocaleString} from '../../../utils/dateParsers';
import {useGetUserProfileQuery} from '../../../redux/api/locales';
import {DocumentActions} from '../../common/documents/documentActions/DocumentActions';
import {CustomLoading} from '../../common/CustomLoading';
import {useDispatch, useSelector} from 'react-redux';
import {
  selectEventTypesDocuments,
  setEventTypesDocumentsFilters,
  setEventTypesDocumentsSorters,
  setEventTypesDocumentsTablePagination,
} from '../../../redux/slices/documents';
import {fromFeToBeSorter} from '../../../utils/parser';
import {DocumentsTableColumnHeader} from './DocumentsTableColumnHeader';
import TableSorter from '../../common/TableSorter';
import {DocumentsFileNameFilter} from './filters/DocumentsFileNameFilter';
import {DocumentsEndDateFilter} from './filters/DocumentsEndDateFilter';
import {DocumentEventTypeFilter} from './filters/DocumentEventTypeFilter';
import {ExpandableTagList} from '../../common/ExpandableTagList';
import {
  isEventTypeDocumentDelete,
  isEventTypeDocumentEdit,
  isEventTypeDocumentView,
} from '../../Authentication/permissions';
import {VALUE_NOT_PRESENT} from "../../../utils/translation";

const {Text} = Typography;

export default function EventTypesDocumentsTableView() {

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [
    selectedRowKeys,
    setSelectedRowKeys,
  ] = useState<React.Key[]>([]);

  const pagination = useSelector(selectEventTypesDocuments).pagination;
  const filters = useSelector(selectEventTypesDocuments).filters;
  const sorters = useSelector(selectEventTypesDocuments).sorters;

  const [
    getDocuments,
    {
      data: documents,
      isLoading: isLoadingDocuments,
      isFetching: isFetchingDocuments,
    },
  ] = useLazyGetDocumentsByEventTypeQuery();
  const {
    data: userData,
    isFetching: isFetchingUserData,
    isLoading: isLoadingUserData,
  } = useGetUserProfileQuery();

  const isEditing = userData?.type ? isEventTypeDocumentEdit(userData?.type) : false;
  const isDelete = userData?.type ? isEventTypeDocumentDelete(userData?.type) : false;
  const isDownload = userData?.type ? isEventTypeDocumentView(userData?.type) : false;

  const [
    deleteDocument,
  ] = useDeleteDocumentMutation();

  useEffect(() => {
    getDocuments({
      page: pagination.current,
      page_size: pagination.pageSize,
      ...(filters.name ? {
        file_name: filters.name?.map(name => name.value).join(','),
      } : {}),
      ...(filters.end_date ? {
        end_date_before: filters.end_date.at(1),
        end_date_after: filters.end_date.at(0),
      } : {}),
      ...(filters.event_types ? {
        event_types: filters.event_types.map(event => event.value).join(','),
      } : {}),
      ...(!_.isEmpty(fromFeToBeSorter(sorters)) ? {
        ordering: fromFeToBeSorter(sorters),
      } : {}),
    });
  }, [filters.end_date, filters.event_types, filters.name, getDocuments, pagination, sorters]);

  const columns = useMemo(() => [
    {
      dataIndex: 'name',
      key: 'name',
      width: 300,
      title: (
        <DocumentsTableColumnHeader
          title={capitalize(t('documents.fileName'))}
          sorter={<TableSorter
            value={sorters.name}
            onChange={type => dispatch(setEventTypesDocumentsSorters({name: type}))}
          />}
          filter={<DocumentsFileNameFilter
            activeFilters={filters.name}
            handleFilterChange={(values) => dispatch(setEventTypesDocumentsFilters({name: values}))}
            viewType={'event_types'}
          />}
          onFilterReset={() => dispatch(setEventTypesDocumentsFilters({name: null}))}
          isFilterDisabled={_.isEmpty(filters.name)}
        />
      ),
    },
    {
      dataIndex: 'end_date',
      key: 'end_date',
      width: 300,
      render: (text: string, record: DocumentType, index: number) => {
        if (userData)
          return record.end_date ? dateToLocaleString(record.end_date, userData.language) :
            <Text type={'secondary'}>{VALUE_NOT_PRESENT}</Text>;
      },
      title: (
        <DocumentsTableColumnHeader
          title={capitalize(t('documents.validityDate'))}
          sorter={<TableSorter
            value={sorters.end_date}
            onChange={type => dispatch(setEventTypesDocumentsSorters({end_date: type}))}
          />}
          filter={<DocumentsEndDateFilter
            activeFilters={filters.end_date}
            handleFilterChange={(values) => dispatch(setEventTypesDocumentsFilters({end_date: [values[0].format('YYYY-MM-DD'), values[1].format('YYYY-MM-DD')]}))}
          />}
          onFilterReset={() => dispatch(setEventTypesDocumentsFilters({end_date: null}))}
          isFilterDisabled={_.isEmpty(filters.end_date)}
        />
      ),
    },
    {
      title: (
        <DocumentsTableColumnHeader
          title={capitalize(t('settings.emergencyType', {count: 2}))}
          sorter={<TableSorter
            value={sorters.event_types}
            onChange={type => dispatch(setEventTypesDocumentsSorters({event_types: type}))}
          />}
          filter={<DocumentEventTypeFilter
            activeFilters={filters.event_types}
            handleFilterChange={(values) => dispatch(setEventTypesDocumentsFilters({event_types: values}))}
          />}
          onFilterReset={() => dispatch(setEventTypesDocumentsFilters({event_types: null}))}
          isFilterDisabled={_.isEmpty(filters.event_types)}
        />
      ),
      width: 300,
      dataIndex: 'event_types',
      key: 'event_types',
      render: (text: string, record: EventTypesDocumentsType, index: number) => {
        return (<ExpandableTagList
          customTag={false}
          tags={record.event_types.map(eventType => ({
            uuid: eventType.uuid,
            content: eventType.event_type_name,
          }))}
        />);
      },
    },
    {
      key: 'action',
      width: 300,
      render: (text: string, record: DocumentType, index: number) => {
        return <DocumentActions
          documentItem={record}
          withDetails={true}
          withDownload={isDownload}
          withEdit={isEditing}
          withDelete={isDelete || !record.protected}
          onDelete={() => deleteDocument({documentUuid: record.uuid}).unwrap()}
        />;
      },
    },
  ], [deleteDocument, dispatch, filters.end_date, filters.event_types, filters.name, isDelete, isDownload, isEditing, sorters.end_date, sorters.event_types, sorters.name, t, userData]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_NONE,
    ],
  };

  return (
    <>
      {!_.isEmpty(selectedRowKeys) && <Col>
        <Space>
          {isDelete && <DeleteMultipleDocuments isDeleteForbidden={documents?.results.some(el => el.protected && selectedRowKeys.includes(el.uuid))} uuids={selectedRowKeys as string[]}/>}
          {isDownload && <DownloadMultipleDocuments uuids={selectedRowKeys as string[]}/>}
        </Space>
      </Col>}
      <Col xs={24}>
        <Table
          columns={columns}
          rowSelection={rowSelection}
          pagination={false}
          loading={
            {
              spinning: isLoadingDocuments || isFetchingDocuments || isLoadingUserData || isFetchingUserData,
              indicator: <CustomLoading/>,
            }
          }
          dataSource={documents?.results.map(document => ({key: document.uuid, ...document}))}
          scroll={{x: true}}
        />
        <Row
          justify={'center'}
          style={{marginTop: '1rem'}}
        >
          <Col>
            <Pagination
              disabled={!documents?.count}
              showSizeChanger={true}
              total={documents?.count}
              showTotal={(total, range) => t('table.pageSizeOfTotal', {
                rangeStart: range[0],
                rangeEnd: range[1],
                total,
              })}
              onChange={(current, pageSize) => {
                dispatch(setEventTypesDocumentsTablePagination({current, pageSize}));
              }}
              {...pagination}
            />
          </Col>
        </Row>
      </Col>
    </>
  );
}