import {Button, Tooltip} from 'antd';
import {Trans, useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {useDeleteDocumentsMutation} from '../../redux/api/documents';
import {useEffect, useState} from 'react';
import {useMessageContext} from '../../context/message/MessageContext';
import DeleteModalMessage from '../common/documents/modals/DeleteModalMessage';

export default function DeleteMultipleDocuments({uuids, isDeleteForbidden = false}: { uuids: string[], isDeleteForbidden?: boolean }) {
  const {t} = useTranslation();

  const setMessage = useMessageContext();

  const [
    isModalOpen,
    setIsModalOpen,
  ] = useState(false);

  const [
    deleteDocuments,
    {
      isLoading: isDeletingDocuments,
      isSuccess: isDocumentsDeleteSuccess,
      isError: isDocumentsDeleteError,
    },
  ] = useDeleteDocumentsMutation();

  useEffect(() => {
    if (isDocumentsDeleteSuccess) {
      setMessage({success: capitalize(t('form.deleteSuccessMessage'))});
    }
  }, [isDocumentsDeleteSuccess, setMessage, t]);

  useEffect(() => {
    if (isDocumentsDeleteError) {
      setMessage({error: capitalize(t('form.deleteFailMessage'))});
    }
  }, [isDocumentsDeleteError, setMessage, t]);

  return <>
    <DeleteModalMessage
      title={{children: capitalize(t('actions.delete'))}}
      paragraph={{
        children: <Trans
          i18nKey={uuids.length === 1 ? 'documents.deleteMultiple_one' : 'documents.deleteMultiple_other'}
          values={{number: uuids.length}}
        />,
      }}
      modal={{
        open: isModalOpen,
        onCancel: () => setIsModalOpen(false),
      }}
      confirmButton={{
        onClick: () => {
          deleteDocuments({documentUuids: uuids});
          setIsModalOpen(false);
        },
      }
      }
    />
    <Tooltip
      title={t("documents.deleteMultipleForbidden")}
    >
      <Button
        disabled={!uuids.length || isDeleteForbidden}
        danger={true}
        onClick={() => setIsModalOpen(true)}
        loading={isDeletingDocuments}
      >
        {capitalize(t('actions.delete'))}
      </Button>
    </Tooltip>
  </>;
}