import {Button, Col, Pagination, Row, Space, Table, Tag, Typography} from 'antd';
import _, {capitalize} from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import DeleteMultipleDocuments from '../DeleteMultipleDocuments';
import {DownloadMultipleDocuments} from '../DownloadMultipleDocuments';
import {
  AssetsDocumentsType,
  DocumentType,
  useDeleteDocumentMutation,
  useLazyGetDocumentsByAssetsQuery,
} from '../../../redux/api/documents';
import {useGetUserProfileQuery} from '../../../redux/api/locales';
import {dateToLocaleString} from '../../../utils/dateParsers';
import {DocumentActions} from '../../common/documents/documentActions/DocumentActions';
import {CustomLoading} from '../../common/CustomLoading';
import {useDispatch, useSelector} from 'react-redux';
import {
  selectAssetsDocuments,
  setAssetsDocumentsFilters,
  setAssetsDocumentsSorters,
  setAssetsDocumentsTablePagination,
} from '../../../redux/slices/documents';
import TableSorter from '../../common/TableSorter';
import {fromFeToBeSorter} from '../../../utils/parser';
import {DocumentsTableColumnHeader} from './DocumentsTableColumnHeader';
import {DocumentsFileNameFilter} from './filters/DocumentsFileNameFilter';
import {DocumentsEndDateFilter} from './filters/DocumentsEndDateFilter';
import {DocumentsAssetFilters} from './filters/DocumentsAssetFilters';
import {isAssetDocumentDelete, isAssetDocumentEdit, isAssetDocumentView} from '../../Authentication/permissions';
import {VALUE_NOT_PRESENT} from "../../../utils/translation";

const {Text} = Typography;

const ExpandableTagList = (props: { assets: { uuid: string, address: string, sigi_code: string }[] }) => {
  const [
    isExpanded,
    setIsExpanded,
  ] = useState(false);

  return (<>
    {(isExpanded) && (
      <Space size={'small'}>
        {props.assets.map(asset => <Tag key={asset.uuid}>{`${asset.sigi_code}-${asset.address}`}</Tag>)}
        {props.assets.length > 1 &&
          <Button type={'link'} onClick={() => setIsExpanded(false)}>riduci</Button>}
      </Space>
    )}
    {(!isExpanded || props.assets.length === 1) && (
      <Space size={'small'}>
        <Tag>{`${props.assets[0].sigi_code}-${props.assets[0].address}`}</Tag>
        {props.assets.length > 1 &&
          <Button
            type={'link'} onClick={() => setIsExpanded(true)}
          >{`+${props.assets.length - 1}`}</Button>}
      </Space>
    )}
  </>);
};

export default function AssetsDocumentsTableView() {

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [
    selectedRowKeys,
    setSelectedRowKeys,
  ] = useState<React.Key[]>([]);

  const pagination = useSelector(selectAssetsDocuments).pagination;
  const filters = useSelector(selectAssetsDocuments).filters;
  const sorters = useSelector(selectAssetsDocuments).sorters;

  const [
    getDocuments,
    {
      data: documents,
      isLoading: isLoadingDocuments,
      isFetching: isFetchingDocuments,
    },
  ] = useLazyGetDocumentsByAssetsQuery();
  const {
    data: userData,
    isFetching: isFetchingUserData,
    isLoading: isLoadingUserData,
  } = useGetUserProfileQuery();

  const isEditing = userData?.type ? isAssetDocumentEdit(userData?.type) : false;
  const isRemove = userData?.type ? isAssetDocumentDelete(userData?.type) : false;
  const isDownload = userData?.type ? isAssetDocumentView(userData?.type) : false;

  const [
    deleteDocument,
  ] = useDeleteDocumentMutation();

  useEffect(() => {
    getDocuments({
      page: pagination.current,
      page_size: pagination.pageSize,
      ...(filters.name ? {
        file_name: filters.name?.map(name => name.value).join(','),
      } : {}),
      ...(filters.end_date ? {
        end_date_before: filters.end_date.at(1),
        end_date_after: filters.end_date.at(0),
      } : {}),
      ...(filters.assets ? {
        assets: filters.assets.map(asset => asset.value).join(','),
      } : {}),
      ...(!_.isEmpty(fromFeToBeSorter(sorters)) ? {
        ordering: fromFeToBeSorter(sorters),
      } : {}),
    });
  }, [filters.assets, filters.end_date, filters.name, getDocuments, pagination, sorters]);

  const columns = useMemo(() => [
    {
      dataIndex: 'name',
      key: 'name',
      title: (
        <DocumentsTableColumnHeader
          title={capitalize(t('documents.fileName'))}
          sorter={<TableSorter
            value={sorters.name}
            onChange={type => dispatch(setAssetsDocumentsSorters({name: type}))}
          />}
          filter={<DocumentsFileNameFilter
            activeFilters={filters.name}
            handleFilterChange={(values) => dispatch(setAssetsDocumentsFilters({name: values}))}
            viewType={'assets'}
          />}
          onFilterReset={() => dispatch(setAssetsDocumentsFilters({name: null}))}
          isFilterDisabled={_.isEmpty(filters.name)}
        />
      ),
    },
    {
      title: (
        <DocumentsTableColumnHeader
          title={capitalize(t('documents.validityDate'))}
          sorter={<TableSorter
            value={sorters.end_date}
            onChange={type => dispatch(setAssetsDocumentsSorters({end_date: type}))}
          />}
          filter={<DocumentsEndDateFilter
            activeFilters={filters.end_date}
            handleFilterChange={(values) => dispatch(setAssetsDocumentsFilters({end_date: [values[0].format('YYYY-MM-DD'), values[1].format('YYYY-MM-DD')]}))}
          />}
          onFilterReset={() => dispatch(setAssetsDocumentsFilters({end_date: null}))}
          isFilterDisabled={_.isEmpty(filters.end_date)}
        />
      ),
      dataIndex: 'end_date',
      key: 'end_date',
      render: (text: string, record: AssetsDocumentsType, index: number) => {
        if (userData)
          return record.end_date ? dateToLocaleString(record.end_date, userData.language) :
            <Text type={'secondary'}>{VALUE_NOT_PRESENT}</Text>;
      },
    },
    {
      title: (
        <DocumentsTableColumnHeader
          title={capitalize(t('assets.asset', {count: 1}))}
          sorter={<TableSorter
            value={sorters.assets}
            onChange={type => dispatch(setAssetsDocumentsSorters({assets: type}))}
          />}
          filter={<DocumentsAssetFilters
            activeFilters={filters.assets}
            handleFilterChange={(values) => dispatch(setAssetsDocumentsFilters({assets: values}))}
          />}
          onFilterReset={() => dispatch(setAssetsDocumentsFilters({assets: null}))}
          isFilterDisabled={_.isEmpty(filters.assets)}
        />
      ),
      key: 'assets',
      dataIndex: 'assets',
      render: (text: string, record: AssetsDocumentsType, index: number) => {
        return (<ExpandableTagList
          assets={record.assets.map(asset => ({
            uuid: asset.uuid,
            address: [asset.address, asset.admin_level_3, asset.admin_level_2, asset.admin_level_1].join(', '),
            sigi_code: asset.sigi_code,
          }))}
        />);
      },
    },
    {
      key: 'action',
      render: (text: string, record: DocumentType, index: number) => {
        return <DocumentActions
          documentItem={record}
          withDetails={true}
          withDownload={isDownload}
          withEdit={isEditing}
          withRemove={isRemove}
          onRemove={() => deleteDocument({documentUuid: record.uuid}).unwrap()}
        />;
      },
    }], [deleteDocument, dispatch, filters.assets, filters.end_date, filters.name, isDownload, isEditing, isRemove, sorters.assets, sorters.end_date, sorters.name, t, userData]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_NONE,
    ],
  };

  useEffect(() => {
    // todo onChange pagination reset selection
  }, []);

  return <>
    {!_.isEmpty(selectedRowKeys) && <Col>
      <Space>
        {isRemove && <DeleteMultipleDocuments uuids={selectedRowKeys as string[]} isDeleteForbidden={documents?.results.some(el => el.protected && selectedRowKeys.includes(el.uuid))}/>}
        {isDownload && <DownloadMultipleDocuments uuids={selectedRowKeys as string[]}/>}
      </Space>
    </Col>}
    <Col xs={24}>
      <Table
        columns={columns}
        pagination={false}
        rowSelection={rowSelection}
        loading={
          {
            spinning: isLoadingDocuments || isFetchingDocuments || isLoadingUserData || isFetchingUserData,
            indicator: <CustomLoading/>,
          }
        }
        dataSource={documents?.results.map(document => ({key: document.uuid, ...document}))}
        scroll={{x: true}}
      />
      <Row
        justify={'center'}
        style={{marginTop: '1rem'}}
      >
        <Col>
          <Pagination
            disabled={!documents?.count}
            showSizeChanger={true}
            total={documents?.count}
            showTotal={(total, range) => t('table.pageSizeOfTotal', {
              rangeStart: range[0],
              rangeEnd: range[1],
              total,
            })}
            onChange={(current, pageSize) => {
              dispatch(setAssetsDocumentsTablePagination({current, pageSize}));
            }}
            {...pagination}
          />
        </Col>
      </Row>
    </Col>
  </>;
}