import {OptionLabelValue} from '../OptionLabelValue';
import {Sorter} from './sorters';
import {AssetsTableRecordFilter} from '../../api/assets';

// FILTERS
const assetsBooleanFilters = [
  'is_closing_date_null',
  'is_opening_date_null',
  'is_surface_basement_null',
  'is_surface_total_null',
  'is_total_resources_null',
] as const;
export type AssetsBooleanFilters = (typeof assetsBooleanFilters)[number];
const isAssetsBooleanFilters = (x: any): x is AssetsBooleanFilters => assetsBooleanFilters.includes(x);

const assetsStringFilters = [
  'closing_date_after',
  'closing_date_before',
  'opening_date_after',
  'opening_date_before',
  'search_by_sigi',
] as const;
export type AssetsStringFilters = (typeof assetsStringFilters)[number];
const isAssetsStringFilters = (x: any): x is AssetsStringFilters => assetsStringFilters.includes(x);

const assetsOptionLabelValueFilters = [
  'address',
  'admin_level_1',
  'admin_level_2',
  'admin_level_3',
  'biodiversity_source',
  'biodiversity_within',
  'building_type',
  'building_type_detail',
  'companies',
  'contact_people',
  'country',
  'is_user_created',
  'master_asset',
  'ownership',
  'polygon_uuid',
  'postal_code',
  'realestate_garrison',
  'realestate_hub',
  'sigi_code',
] as const;
export type AssetsOptionLabelValueFilters = (typeof assetsOptionLabelValueFilters)[number];
export const isAssetsOptionLabelValueFilters = (x: any): x is AssetsOptionLabelValueFilters => assetsOptionLabelValueFilters.includes(x);

const assetsNumberFilters = [
  'biodiversity_distance',
  'surface_basement_max',
  'surface_basement_min',
  'surface_total_max',
  'surface_total_min',
  'total_resources_max',
  'total_resources_min',
  'total_uog_contacts_max',
  'total_uog_contacts_min',
] as const;
export type AssetsNumberFilters = (typeof assetsNumberFilters)[number];
const isAssetsNumberFilters = (x: any): x is AssetsNumberFilters => assetsNumberFilters.includes(x);

export type AssetTableFilter =
  AssetsStringFilters
  | AssetsOptionLabelValueFilters
  | AssetsBooleanFilters
  | AssetsNumberFilters
  | 'hazards'

export type StaticAssetsFilters = (typeof assetsBooleanFilters | typeof assetsStringFilters | typeof assetsOptionLabelValueFilters | typeof assetsNumberFilters)[number];
export const isStaticAssetsFilters = (x: any): x is StaticAssetsFilters => [...assetsBooleanFilters, ...assetsStringFilters, ...assetsOptionLabelValueFilters, ...assetsNumberFilters].includes(x);

export type AssetsFiltersFE = Partial<
  Record<AssetsBooleanFilters, boolean> &
  Record<AssetsStringFilters, string> &
  Record<AssetsOptionLabelValueFilters, OptionLabelValue[]> &
  Record<AssetsNumberFilters, number> &
  {
    hazards: {
      [hazard: string]: OptionLabelValue[]
    }
  }
>

export function fromFeToBeAssetsFilters(tableFilters: AssetsFiltersFE, excludeFilters: AssetsOptionLabelValueFilters[]) {
  let parsedFilters: AssetsTableRecordFilter = {};
  for (const [key, value] of Object.entries(tableFilters)) {
    if (isAssetsBooleanFilters(key) || isAssetsStringFilters(key) || isAssetsNumberFilters(key)) {
      parsedFilters = {...parsedFilters, [key]: value};
    } else if (isAssetsOptionLabelValueFilters(key)) {
      const parsedValue = (value as OptionLabelValue[]).map(el => el.value)
        .join(key === 'address' ? '|' : ',');
      parsedFilters = {...parsedFilters, [!excludeFilters.includes(key) ? key : `${key}_not`]: parsedValue};
    } else if (key === 'hazards' && tableFilters.hazards) {
      const hazardFilter: string[] = [];
      for (const [hazard, hazardValues] of Object.entries(tableFilters.hazards)) {
        hazardFilter.push(`${hazard}=${hazardValues.map(el => el.value).join(',')}`);
      }
      parsedFilters = {
        ...parsedFilters,
        ...hazardFilter.length > 0 && {hazards: hazardFilter.join('|')},
      };
    }
  }
  return parsedFilters;
}

export function getFiltersOfTheAssetsTableStaticColumn(column: AssetsTableStaticColumn) {
  const filters: AssetTableFilter[] = [];
  switch (column) {
    case 'surface_basement':
    case 'surface_total':
    case 'total_resources':
      filters.push(`${column}_min`, `${column}_max`, `is_${column}_null`);
      break;
    case 'total_uog_contacts':
      filters.push(`${column}_min`, `${column}_max`);
      break;
    case 'biodiversity_within':
      filters.push('biodiversity_distance', 'biodiversity_within', 'biodiversity_source');
      break;
    case 'closing_date':
    case 'opening_date':
      filters.push(`${column}_after`, `${column}_before`, `is_${column}_null`);
      break;
    case 'map_view':
      break;
    default:
      filters.push(column);
      break;
  }
  return filters;
}

// SORTERS
export type AssetsSortersFE = Partial<Omit<{
    [K in AssetsTableStaticColumn]: Sorter
  }, 'map_view' | 'uuid'>>
//     hazard sorters
  & { [key: string]: Sorter }

// COLUMNS
export const assetsTableStaticColumns = [
  'address',
  'admin_level_1',
  'admin_level_2',
  'admin_level_3',
  'biodiversity_within',
  'building_type',
  'building_type_detail',
  'closing_date',
  'companies',
  'contact_people',
  'country',
  'is_user_created',
  'map_view',
  'master_asset',
  'opening_date',
  'ownership',
  'postal_code',
  'realestate_garrison',
  'realestate_hub',
  'sigi_code',
  'surface_basement',
  'surface_total',
  'total_resources',
  'total_uog_contacts',
] as const;

export type AssetsTableStaticColumn = (typeof assetsTableStaticColumns)[number]
export const isAssetsTableStaticColumn = (x: any): x is AssetsTableStaticColumn => assetsTableStaticColumns.includes(x);
export type AssetsTableColumn = AssetsTableStaticColumn | string

//COLUMNS DIVIDED BY TYPE
export type PositionColumn = Extract<AssetsTableStaticColumn, 'country' | 'admin_level_1' | 'admin_level_2' | 'admin_level_3' | 'postal_code' | 'address'>
export type BuildingDetailsColumn = Extract<AssetsTableStaticColumn, 'sigi_code' | 'building_type' | 'building_type_detail' | 'surface_total' | 'surface_basement' | 'opening_date' | 'closing_date' | 'building_status' | 'ownership' |
  'contact_people' | 'master_asset' | 'companies' | 'total_resources' | 'total_uog_contacts' | 'is_user_created' | 'biodiversity' | 'biodiversity_within'>
export type RealEstateColumn = Exclude<AssetsTableStaticColumn, PositionColumn | BuildingDetailsColumn>
