import {CustomLoading} from '../CustomLoading';
import {List} from 'antd';
import {DocumentListItem} from '../../asset/documents/DocumentListItem';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {DocumentType} from '../../../redux/api/documents';

export default function DocumentsList({
                                        documents,
                                        total,
                                        page,
                                        setPage,
                                        loading,
                                        pageSize,
                                        setPageSize,
                                        onRemove,
                                        onDelete,
                                        withEdit,
                                        withDownload,
                                      }: {
  documents?: DocumentType[],
  total?: number,
  loading?: boolean,
  page?: number,
  pageSize?: number,
  setPage?: (value: number) => void,
  setPageSize?: (value: number) => void,
  onRemove?: (documentUuid: string) => Promise<any>,
  onDelete?: (documentUuid: string) => Promise<any>,
  withEdit?: boolean,
  withDownload?: boolean
}) {

  const {t} = useTranslation();

  return <List
    dataSource={documents}
    header={null}
    footer={null}
    {...typeof loading === 'boolean' && {
      loading:
        {
          spinning: loading,
          indicator: <CustomLoading/>,
        },
    }}
    {...!!(page && setPage && pageSize && setPageSize) && {
      pagination: {
        pageSize: pageSize,
        total: total,
        showSizeChanger: true,
        current: page,
        onChange: page => setPage(page),
        onShowSizeChange: (current, pagesize) => {
          setPage(current);
          setPageSize(pagesize);
        },
        showTotal: (total, range) => t('table.pageSizeOfTotal', {
          rangeStart: range[0],
          rangeEnd: range[1],
          total,
        }),
        position: 'bottom',
        align: 'center',
      },
    }}

    renderItem={(item) => (
      <List.Item>
        <DocumentListItem
          documentItem={item}
          {...!!onRemove && !item.protected && {
            onRemove: () => onRemove(item.uuid),
          }}
          {...!!onDelete && {
            onDelete: () => onDelete(item.uuid),
          }}
          withEdit={withEdit}
          withDownload={withDownload}
        />
      </List.Item>
    )}
  />;
}